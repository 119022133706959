import { useState } from "react";
import UploadResize from "../../../molecules/custom/upload-image/uploadResize";
import Modal from "../../../molecules/modal";
import { AddImage } from "../../../../assets/images";
import { Button, Input, JoditTextEditor } from "../../../atoms";
import JodithConfig from "../../../../helpers/jodithConfig";

const FormAdd = (props) => {
  const { insertHandler, display, handlerClose } = props;
  const [config] = useState(JodithConfig(true));
  const [data, setData] = useState({
    image: null,
    title: "",
    content: "",
  });
  const releasePost = () => {
    insertHandler({ ...data, status_release: "Release" });
  };

  const draftPost = (e) => {
    insertHandler({ ...data, status_release: "Draft" });
  };

  return (
    <Modal open={display} showButton={true} handlerClose={() => handlerClose()}>
      <div className="w-[800px] h-fit flex p-4">
        <div className="w-1/4">
          <div className="w-full h-[200px]">
            <UploadResize
              imgSrc={data?.image || AddImage}
              setImageSrc={(e) => setData({ ...data, image: e })}
              aspect={16 / 9}
            />
          </div>
        </div>
        <div className="w-3/4 flex-col p-4">
          <div className="mb-2">
            <label>Title</label>
            <Input
              classes={"border border-dark w-full p-2 rounded-md"}
              classesInput={"w-full outline-none"}
              value={data.title}
              handler={(e) => setData({ ...data, title: e.target.value })}
            />
          </div>
          <div className="mb-2">
            <label>Content</label>
            <JoditTextEditor
              config={config}
              content={data.content}
              setContent={(e) => setData({ ...data, content: e })}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <Button
          classes={"rounded-md bg-dark text-white p-2 w-full"}
          text={"Publish"}
          handler={() => releasePost()}
        />
        <Button
          classes={
            "rounded-md bg-transparent hover:bg-dark/50 hover:text-white text-dark border border-dark mt-2 p-2 w-full"
          }
          text={"Save As Draft"}
          handler={() => draftPost()}
        />
      </div>
    </Modal>
  );
};

export default FormAdd;
