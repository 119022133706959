import { useEffect, useState } from "react";
import UploadImage from "../../../molecules/custom/upload-image/uploadResize";
import Modal from "../../../molecules/modal";
import { AddImage } from "../../../../assets/images";
import { Button, Input, JoditTextEditor, SelectBar } from "../../../atoms";
import JodithConfig from "../../../../helpers/jodithConfig";
import environtment from '../../../../constant/environtment.json';
import UploadResize from "../../../molecules/custom/upload-image/uploadResize";

const FormEdit = (props) => {
  const { updateHandler, display, handlerClose, initialData, environtment } =
    props;
  const [config] = useState(JodithConfig(true));
  const [data, setData] = useState({
    id: null,
    image: null,
    name: "",
  });
  const submitEdit = (e) => {
    e.preventDefault();
    updateHandler(data);
  };

  useEffect(() => {
    if (display) {
      setData(initialData);
    }
  }, [display, initialData]);

  console.log('init', data);

  return (
    <Modal open={display} showButton={true} handlerClose={() => handlerClose()}>
      <form onSubmit={submitEdit}>
        <div className="w-[800px] h-fit flex p-4">
          <div className="w-1/4">
            <div className="w-full h-[200px]">
              <UploadResize
                imgSrc={data?.image ? `${environtment?.REACT_APP_SOURCE_URL}${data?.image}` : AddImage}
                setImageSrc={(e) => setData({ ...data, image: e })}
                aspect={1 / 1}
              />
            </div>
          </div>
          <div className="w-3/4 flex-col p-4">
            <div className="mb-2">
              <label>Title</label>
              <Input
                classes={"border border-dark w-full p-2 rounded-md"}
                classesInput={"w-full outline-none"}
                value={data.title}
                handler={(e) => setData({ ...data, title: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label>Category</label>
              <SelectBar
                data={[{
                  id: "PAY IN",
                  name: "PAY IN"
                },{
                  id: "PAY OUT",
                  name: "PAY OUT"
                }]}
                value={data?.category}
                handler={(e) => setData({...data, category: e.target.value})}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Button
            classes={"rounded-md bg-dark text-white p-2 w-full"}
            text={"Submit"}
            type={"submit"}
          />
        </div>
      </form>
    </Modal>
  );
};

export default FormEdit;
