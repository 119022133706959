import FormEditAbout from '../../molecules/form/aboutUs/formEditAboutUs';
import '../style.css';
const Screen = (props) => {
  const {data, updateHandler} = props;
  return(
    <div className="container-content">
      <div className='container-content-border'>
        <FormEditAbout 
          handlerUpdate={(e) => updateHandler(e)}
          data={data}
        />
      </div>      
    </div>
  )
}

export default Screen;