import { Logo, BgImage } from "../../../assets/images";
import FormLogin from "../../organisms/form/formLogin";
import './style.css';

const Screen = (props) => {
  const { onSubmit } = props;
  return (
    <div className="container-login">
      <div className="w-full h-full flex bg-fixed" style={{ backgroundImage: `url(${BgImage})` }} classes="bg-local bg-cover bg-center w-full h-full">
      
      <div className="w-1/2 bg-dark/70 text-white flex justify-center p-10">
          <img src={Logo} alt='...' className="object-contain"/>
      </div>

      <div className="w-1/2 h-full flex flex-col justify-center p-4">
          <div className="shadow-lg rounded-md p-10 bg-white">
              <FormLogin
                onSubmit={(e) => onSubmit(e)}
              />
          </div>        
        </div>
      </div>
    </div>
  )
}

export default Screen;