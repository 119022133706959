import Loading from "../../templates/loading";
import PrivateTemplate from "../../templates/privateTemplates";
import Services from "./services";
import Screen from "./screen";
const WhyShouldPartner = () => {
  const { loading, meta, setMeta, dataServices, addwhyShouldPartner, deletewhyShouldPartner, updatewhyShouldPartner } = Services();
  return (
    <PrivateTemplate>
      <Loading display={loading} />
      <Screen 
        meta={meta} 
        setMeta={(e) => setMeta(e)} 
        data={dataServices}
        insertHandler={(e) => addwhyShouldPartner(e)}
        deleteHandler={(e) => deletewhyShouldPartner(e)}
        updateHandler={(e) => updatewhyShouldPartner(e)}
      />
    </PrivateTemplate>
  );
};

export default WhyShouldPartner;
