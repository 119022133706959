import PropTypes from 'prop-types';
import { Button } from '../../atoms';
import {AiOutlineClose} from 'react-icons/ai';
const Modal = (props) => {
  const {
    open,
    handlerClose,
    children,
    showButton,
    zIndex
  } = props;

  return (
    <div className={`overflow-hidden fixed top-0 right-0 left-0 ${zIndex} w-full md:inset-0 h-screen md:h-full ${!open && 'hidden'} bg-dark/50`}>
      <div className='flex h-full w-full justify-center items-center'>
        <div className='max-h-screen w-fit overflow-y-scroll scroll-green bg-white border-blue-30 rounded-md p-2'>
          <div className='flex flex-col w-full h-fit relative'>
            {showButton && (
              <Button classes={'absolute right-0 top-0 outline-none w-fit h-fit'} handler={() => handlerClose()}>
                <AiOutlineClose />
              </Button>  
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  handlerClose: PropTypes.func,
  children: PropTypes.element,
  showButton: PropTypes.bool,
  zIndex: PropTypes.string,
}

Modal.defaultProps = {
  open: false,
  handlerClose: () => {},
  children: <></>,
  showButton: false,
  zIndex: 'z-10'
}

export default Modal;