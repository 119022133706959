import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import PropTypes from "prop-types";
import alertMessage from "../../helpers/alertMessage";

const JoditTextEditor = (props) => {
  const editor = useRef(null);
  const { config, content, setContent, limit} = props;
  const [initialValue, setInitialValue] = useState('');

  useEffect(() => {
    setInitialValue(content);
  },[content])

  const onChange = (e) => {
    const stringVal = e.replace(/(<([^>]+)>|&nbsp;)/gi, "");
    if(stringVal.length > limit){
      alertMessage('error', `Max ${limit} character`, "Ok", ()=>setInitialValue(e.slice(0, limit - 1)));
    }else{
      setInitialValue(e);
    }
  }
  return (
    <JoditEditor
      ref={editor}
      config={config}
      value={initialValue}
      tabIndex={1} // tabIndex of textarea
      onBlur={() => setContent(initialValue)} // preferred to use only this option to update the content for performance reasons
      onChange={onChange}
    />
  );
};

JoditTextEditor.propTypes = {
  config: PropTypes.object,
  content: PropTypes.string,
  setContent: PropTypes.func,
  onChange: PropTypes.func,
  limit: PropTypes.number
};

JoditEditor.defaultProps = {
  limit: Infinity
}

export default JoditTextEditor;
