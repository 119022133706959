const initialState = {
  data : [],
  loading : false
}
export const GetInvestmentProcedures = (state = initialState, action = {}) => {
  switch(action.type){
    case 'GET_SETUP_COMPANY_SUCCESS':
      return {
        loading: false,
        data: action.payload
      }
    case 'GET_SETUP_COMPANY_FAILED':
      return {
        ...state,
        loading: false,
        data: []
      }
    case 'GET_YOUR_INVESTMENT_SUCCESS':
      return {
        loading: false,
        data: action.payload
      }
    case 'GET_YOUR_INVESTMENT_FAILED':
      return {
        ...state,
        loading: false,
        data: []
      }
    default :
    return state;
  }
}

export const UpdateInvestmentProcedures = (state = initialState, action = {}) => {
  switch(action.type){
    case 'UPDATE_SETUP_COMPANY_SUCCESS':
      return {
        loading: false,
        data: action.payload
      }
    case 'UPDATE_SETUP_COMPANY_FAILED':
      return {
        ...state,
        loading: false,
        data: []
      }
    case 'UPDATE_YOUR_INVESTMENT_SUCCESS':
      return {
        loading: false,
        data: action.payload
      }
    case 'UPDATE_YOUR_INVESTMENT_FAILED':
      return {
        ...state,
        loading: false,
        data: []
      }
    default :
    return state;
  }
}