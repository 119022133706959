import { useState } from "react";
import { GlobalGet } from "../../../helpers/fetcher";
import Environtment from "../../../constant/environtment.json";
import alertMessage from "../../../helpers/alertMessage";
import { useSelector } from "react-redux";

const Services = () => {
  const { data } = useSelector((state) => state.UserLogin);
  const {REACT_APP_API_URL} = Environtment;
  const [headers] = useState({
    Authorization: data?.access_token,
  });
  const [countData, setCountData] = useState({
    countPartners: 0,
  })
  const [loading, setLoading] = useState(false);

  const fetchPartners = async () => {
    setLoading(true);
    try {
      const response = await GlobalGet({
        url: `${REACT_APP_API_URL}/partners`,
      });
      console.log('res', response);
      if (response?.status === 200) {
        setLoading(false);
        return response?.data?.length;
        // setCountData({...countData, countNews: response?.data?.post?.length})
      } else {
        setLoading(false);
        alertMessage("error", response?.message, "Retry", () =>
          window.location.reload()
        );
      }
    } catch (err) {
      setLoading(false);
      alertMessage("network_error", err, "Retry", () =>
        window.location.reload()
      );
    }
  };
  const fetchData = async () => {
    setCountData({
      countPartners: await fetchPartners()
    });
  };

  useState(() => {
    fetchData();
  }, []);

  return {
    loading,
    countData,
  }
}

export default Services;