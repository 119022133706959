import PrivateTemplate from "../../templates/privateTemplates";
import Screen from "./screen";
import Services from "./services";
const AboutUs = () => {
  const {
    aboutUs,
    updateAboutUs,
  } = Services();
  return (
    <PrivateTemplate>
      <Screen 
        data={aboutUs}
        updateHandler={(e) => updateAboutUs(e)}
      />
    </PrivateTemplate>
  );
};

export default AboutUs;
