import PrivateTemplate from "../../../templates/privateTemplates";
import Services from "./services";
import Screen from "./screen";
import Loading from "../../../templates/loading";
const PaymentServices = () => {
  const {
    loading,
    dataPayment,
    addPayment,
    deletePayment,
    meta,
    setMeta,
    updatePayment,
  } = Services();
  return (
    <PrivateTemplate>
      <Loading display={loading}/>
      <Screen
        meta={meta}
        setMeta={(e) => setMeta(e)}
        data={dataPayment}
        insertHandler={(e) => addPayment(e)}
        deleteHandler={(e) => deletePayment(e)}
        updateHandler={(e) => updatePayment(e)}
      />
    </PrivateTemplate>
  )
}

export default PaymentServices;