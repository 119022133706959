import '../style.css';
import { useState } from 'react';
import { Button } from '../../atoms';
import TableNews from '../../organisms/table/tbNews';
import { FormAdd } from '../../organisms/form/news';
import Pagination from '../../molecules/pagination';
const Screen = (props) => {
  const {data, insertHandler, deleteHandler, updateHandler, meta, setMeta} = props;
  const [showAdd, setShowAdd] = useState(false);
  return (
    <main className="container-content">
      <div className="w-full h-fit">
      <div className="flex justify-between mb-2">
        <div>
          <span className="text-3xl">Data Post</span>
        </div>
        <Button
          text={'Add Content'}
          classes="text-white p-2 bg-dark rounded-sm hover:bg-dark/50"
          handler={() => setShowAdd(true)}
        />
      </div>
      <TableNews
        data={data}
        deleteHandler={(e) => deleteHandler(e)}
        updateHandler={(e) => updateHandler(e)}
      />
      <FormAdd
        display={showAdd}
        handlerClose={() => setShowAdd(false)}
        insertHandler={(e) => insertHandler(e)}
      />
    </div>
    <div className="my-3 flex justify-between items-center px-3">
      <span>Showing {meta?.page} - {meta.total_data} data</span>
        <Pagination
          maxPage={meta.max_page}
          totalData={meta.limit}
          setRows={(value) => setMeta({ ...meta, limit: value, page: 1 })}
          active={meta?.page}
          total={meta?.total_page}
          handler={(value) => setMeta({ ...meta, page: value })}
        />
      </div>
    </main>
  );
}

export default Screen;