import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import alertMessage from "../../../helpers/alertMessage";
import { Button } from "../../atoms";
import Modal from "../../molecules/modal";
import FormEdit from "../form/use-case/formEdit";
import LimitDescription from "../../../helpers/limitDescrption";
import environtment from '../../../constant/environtment.json';

const TableUseCase = (props) => {
  const {data, deleteHandler, updateHandler} = props;
  const [showEdit, setShowEdit] = useState(false);
  const [dataId, setDataId] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const deleteAction = (id) => {
    alertMessage("confirmation", "Apakah kamu yakin ?", null, () =>
      deleteHandler(id)
    );
  };

  return (
    <div className="w-full h-full">
      <table className="table-auto w-full text-sm">
        <thead>
          <tr>
            <th className="bg-dark border border-white p-2 text-white">No.</th>
            <th className="bg-dark border border-white p-2 text-white">
              Title
            </th>
            <th className="bg-dark border border-white p-2 text-white">
              Image
            </th>
            <th
              className="bg-dark border border-white p-2 text-white"
              colSpan={2}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((item, index) => (
              <tr className="border-gray-300 border-b">
                <td className="p-2">{index + 1}</td>
                <td className="p-2">{item?.title}</td>
                <td>
                  <div
                    className="w-full text-center h-6"
                  >
                    <span 
                      className="text-blue-500 underline cursor-pointer"
                      onClick={() => {
                        setImgSrc(item?.image);
                        setShowImage(true);
                      }}
                      >
                      Lihat
                    </span>
                  </div>
                </td>
                <td className="flex items-center justify-center">
                  <Button
                    handler={() => {
                      setDataId(index);
                      setShowEdit(true);
                    }}
                    classes="outline-none w-fit p-2 flex"
                  >
                    <FaPen fill="#12385C" />
                  </Button>
                  <Button
                    classes="outline-none w-fit p-2"
                    handler={() => deleteAction(item?.id)}
                  >
                    <FaTrash fill="#A9423E" />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <FormEdit
        updateHandler={(e) => updateHandler(e)}
        display={showEdit}
        environtment={environtment}
        initialData={data[dataId] || {}}
        handlerClose={() => setShowEdit(false)} 
      />
      <Modal
        handlerClose={() => setShowImage(false)}
        open={showImage}
        showButton
      >
        <div className="w-full h-full">
          <img src={`${environtment?.REACT_APP_SOURCE_URL}/${imgSrc}`} alt="..." className="h-full w-full object-contain" />
        </div>
      </Modal>
    </div>
  );
}

export default TableUseCase;