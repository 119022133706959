import { GlobalPost } from "../../fetcher";
import alertMessage from "../../alertMessage";
import Environtment from '../../../constant/environtment.json';

const {REACT_APP_API_URL} = Environtment;

const UserLoginRequest = () => {
  return { type: "USER_LOGIN_REQUEST" };
};
const UserLoginSuccess = (data) => {
  return { type: "USER_LOGIN_SUCCESS", payload: data };
};
const UserLoginError = (err) => {
  return { type: "USER_LOGIN_ERROR", payload: err };
};
export const UserLogout = () => {
  return { type: "USER_LOGOUT" };
};

export const UserLogin = (formData, cbLoading, cbRouter) => {
  return (dispatch) => {
    cbLoading(true);
    dispatch(UserLoginRequest());
    return GlobalPost({
      url: `${REACT_APP_API_URL}/auth/login`,
      data: formData,
      headers:{
        'Content-Type' : 'application/json'
      }
    })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(UserLoginSuccess(res.data));
          cbLoading(false);
          cbRouter("/");
        } else {
          dispatch(UserLoginError(res));
          cbLoading(false);
          alertMessage("error", res?.message, "Retry");
        }
      })
      .catch((err) => {});
  };
};

const UserChangePasswordSuccess = (data) => {
  return { type: "USER_CHANGE_PASSWORD_SUCCESS", payload: data };
};
const UserChangePasswordFailed = (err) => {
  return { type: "USER_CHANGE_PASSWORD_FAILED", payload: err };
};
export const UserChangePassword = (formData, cbLoading, token) => {
  return (dispatch) => {
    return GlobalPost({
      url: `${REACT_APP_API_URL}api/v1/admin/change-password`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res) => {
        if (res?.status) {
          dispatch(UserChangePasswordSuccess(res.data));
          cbLoading(false);
          alertMessage("success", res?.message, "Ok", () =>
            window.location.reload()
          );
        } else {
          dispatch(UserChangePasswordFailed(res));
          cbLoading(false);
          alertMessage("error", res?.message, "Retry");
        }
      })
      .catch((err) => {
        dispatch(UserChangePasswordFailed(err));
        cbLoading(false);
        alertMessage("error", err?.message, "Retry");
      });
  };
};
