import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { isLogin } = useSelector((state) => state.UserLogin);
  return (isLogin) ? (
    <Outlet/>
  ) : (
    <Navigate replace to="/login" />
  )
}

export default PrivateRoute