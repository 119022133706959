import PrivateTemplate from "../../templates/privateTemplates";
import Services from "./services";
import Screen from "./screen";
import Loading from "../../templates/loading";
const UseCase = () => {
  const {
    dataUseCase,
    loading,
    meta,
    setMeta,
    addUseCase,
    deleteUseCase,
    updateUseCase,
  } = Services();
  return (
    <PrivateTemplate>
      <Loading display={loading}/>
      <Screen
        meta={meta}
        setMeta={(e) => setMeta(e)}
        data={dataUseCase}
        insertHandler={(e) => addUseCase(e)}
        deleteHandler={(e) => deleteUseCase(e)}
        updateHandler={(e) => updateUseCase(e)}
      />
    </PrivateTemplate>
  )
}

export default UseCase;