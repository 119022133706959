const initialState = {
  data : [],
  loading : false
}

const GetFooter = (state = initialState, action = {}) => {
  switch(action.type){
      case 'GET_FOOTER_SUCCESS':
          return{
              loading : false,
              data : action.payload
          }
      case 'GET_FOOTER_FAILED':
          return{
              ...state,
              loading : false,
              data : []
          }
      default :
          return state
  }
}

export default GetFooter