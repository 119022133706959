const initialState = {
  data: [],
  loading: false,
};

export const UserLogin = (state = initialState, action = {}) => {
  switch (action.type) {
    case "USER_LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
        isLogin: false,
      };
    case "USER_LOGIN_SUCCESS":
      return {
        loading: false,
        isLogin: true,
        data: action.payload,
      };
    case "USER_LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        isLogin: false,
        data: [],
      };
    case "USER_LOGOUT":
      return {
        data: [],
        loading: false,
        isLogin: false,
      };
    default:
      return state;
  }
};

export const UserChangePassword = (state = initialState, action = {}) => {
  switch (action.type) {
    case "USER_CHANGE_PASSWORD_SUCCESS":
      return {
        data: action.payload,
        loading: false,
      };
    case "USER_CHANGE_PASSWORD_FAILED":
      return {
        ...state,
        loading: false,
        data: [],
      };
    default:
      return state;
  }
};
