const initialState = {
  data: [],
  loading: false
}
const GetSocialMedia = (state = initialState, action = {}) => {
  switch(action.type){
    case 'GET_SOCIAL_MEDIA_SUCCESS':
      return {
        loading: false,
        data: action.payload
      }
    case 'GET_SOCIAL_MEDIA_FAILED':
      return {
        data: [],
        loading: false,
        ...state
      }
    default:
      return state
  }
}

export default GetSocialMedia