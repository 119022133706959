import "../style.css";
import Card from "../../molecules/card";
import { BsNewspaper } from "react-icons/bs";
import { MdBusinessCenter, MdConnectWithoutContact, MdOutlineFeedback } from "react-icons/md";
import { useNavigate, useLocation } from 'react-router-dom';

const Screen = (props) => {
  const navigate = useNavigate();
  const { countData } = props;
  console.log('count', countData)
  return (
    <main className="container-content">
      <div className="w-full h-fit flex flex-wrap items-stretch">
        <div className="w-2/6 p-4">
          <Card
            desc={"PARTNERS"}
            title={countData?.countPartners}
            variant={"card-red"}
            icon={<MdConnectWithoutContact size={60} />}
            handler={()=>navigate('/partners')}
          />
        </div>
        {/* <div className="w-2/6 p-4">
          <Card
            desc={"NEWS"}
            title={countData?.countNews}
            icon={<BsNewspaper size={60} />}
            handler={()=>navigate('/news')}
          />
        </div>
        <div className="w-2/6 p-4">
          <Card
            desc={"TOTAL DATA FEEDBACK"}
            title={countData?.countFeedback}
            variant={"card-yellow"}
            icon={<MdOutlineFeedback size={60} />}
            handler={()=>navigate('/feedback')}
          />
        </div>
        <div className="w-2/6 p-4">
          <Card
            desc={"TOTAL DATA OUR BUSINESS"}
            title={countData?.countBusiness}
            variant={"card-green"}
            icon={<MdBusinessCenter size={60} />}
            handler={()=>navigate('/our-business')}
          />
        </div> */}
      </div>
    </main>
  );
};

export default Screen;
