import PropTypes from 'prop-types';
const Description = (props) => {
  const {text} = props;
  return (
    <div>
      <p>{text}</p>
    </div>
  )
}

Description.propTypes = {
  text: PropTypes.string,
}

Description.defaultProps = {
  text: ''
}

export default Description;