const initialState = {
  data : [],
  loading : false
}

export const GetAboutUs = (state = initialState, action = {}) => {
  switch(action.type){
      case 'GET_ABOUT_US_SUCCESS':
          return{
              loading : false,
              data : action.payload
          }
      case 'GET_ABOUT_US_FAILED':
          return{
              ...state,
              loading : false,
              data : []
          }
      default :
          return state
  }
}

export const UpdateAboutUs = (state = initialState, action = {}) => {
  switch(action.type){
      case 'UPDATE_ABOUT_US_SUCCESS':
          return{
              loading : false,
              data : action.payload
          }
      case 'UPDATE_ABOUT_US_FAILED':
          return{
              ...state,
              loading : false,
              data : []
          }
      default :
          return state
  }
}