import PrivateTemplate from "../../templates/privateTemplates";
import Screen from "./screen";
import Services from "./services";
import Loading from "../../templates/loading";
const index = () => {
  const {
    addParner, 
    partners, 
    meta, 
    setMeta,
    loading,
    deletePartner,
    updatePartner,
    Environtment,
  } = Services();

  return (
    <PrivateTemplate>
      <Loading 
        display={loading}
      />
      <Screen
        data={partners}
        meta={meta}
        environtment={Environtment}
        setMeta={(e) => setMeta(e)}
        insertHandler={(e) => addParner(e)}
        deleteHandler={(e) => deletePartner(e)}
        updateHandler={(e) => updatePartner(e)}
      />
    </PrivateTemplate>
  )
}

export default index;