import { useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { AddImage } from "../../../../assets/images";
import ResizeCanvas from "./rezizeCanvas";
import PropTypes from 'prop-types';

const UploadResize = (props) => {
  const {imgSrc, setImageSrc, width, height, required, aspect, disabled} = props;
  const inputRef = useRef();
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [options, setOptions] = useState({})
  const [showCropper, setShowCropper] = useState(false);
  const onChangeImage = (e) => {
    setOptions({
      name: e[0]?.name,
      size: e[0]?.size,
      type: e[0]?.type,
    })
    if (e[0] !== null && e[0] !== undefined) {
      setImageUrl(URL.createObjectURL(e[0]));
      setShowCropper(true);
    }
  };

  const resetFile = ()=>{
    inputRef.current.value = null;
  }
  useEffect(() => {
    if(image){
      setImageSrc(image);
      resetFile();
    }
  }, [image]);

  return (
    <div className="h-full w-full">
      <div className={`h-full w-full border`}  onClick={()=>inputRef.current.click()}>
      <img src={typeof(imgSrc) === 'string' ? imgSrc : (image && URL.createObjectURL(image)) || AddImage} alt='...' className="object-contain w-full h-full"/>
      <input
        // required={required}
        disabled={disabled}
        type="file"
        ref={inputRef}
        id="file-input"
        onChange={(e) => onChangeImage(e.target.files)}
        accept="image/*"
        style={{ display: "none" }}
      />
      </div>
      <ResizeCanvas
        options={options}
        show={showCropper}
        setShow={(e) => setShowCropper(e)}
        imageUrl={imageUrl || AddImage}
        setImageUrl={(e) => setImage(e)}
        resetFile={()=>resetFile()}
        width={width}
        height={height}
        aspect={aspect}
      />
    </div>
  );
};

UploadResize.propTypes = {
  imgSrc: PropTypes.string,
  setImageSrc: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  aspect: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

UploadResize.defaultProps = {
  imgSrc: null,
  setImageSrc: () => {},
  width: null,
  height: null,
  aspect: null,
  required: false,
  disabled: false
}


export default UploadResize;
