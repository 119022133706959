import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import Environtment from '../../../constant/environtment.json';
import alertMessage from '../../../helpers/alertMessage';
import { GlobalPut, GlobalGet } from '../../../helpers/fetcher';
const Services = () => {
  const {REACT_APP_API_URL} = Environtment;
  const { data } = useSelector((state) => state.UserLogin);
  console.log('user login', data);
  const [headers] = useState({
    "user-token": data?.token,
  });
  const [loading, setLoading] = useState(false);
  const [aboutUs, setAboutUs] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    total_page: 0,
    total_result: 0,
    total_data: 0,
    limit: 10,
    max_page: 2,
  });
  const updateAboutUs = async(params) => {
    setLoading(true);
    try{
      const response = await GlobalPut({
        url : `${REACT_APP_API_URL}/about-us/${params?.id}`,
        data : {
          title: params?.title,
          sub_title: params?.sub_title,
          description: params?.description,
        },
        headers: { ...headers, "Content-Type": "application/json" },
      })

      if(response?.status === 200){
        setLoading(false);
        alertMessage('success', response?.message, 'Ok', () => window.location.reload())
      }else{
        setLoading(false);
        alertMessage('error', response?.message, 'Retry', ()=>{});
      }
    }catch(err){
      setLoading(false);
      alertMessage('error', err, 'Retry', ()=>{});
    }
  }
  const fetchAboutUs = async() => {
    setLoading(true);
    try {
      const response = await GlobalGet({
        url: `${REACT_APP_API_URL}/about-us`,
        // params: meta
      });
      console.log('res', response);
      if(response?.status === 200) {
        setAboutUs(response?.data[0]);
        setLoading(false);
      } else {
        setLoading(false);
        setAboutUs([]);
        alertMessage('error', response?.message, 'Retry', ()=>{});
      }
    }catch(err){
      setLoading(false);
      alertMessage('error', err, 'Retry', ()=>{});
    }
  }
  
  useEffect(()=>{
    fetchAboutUs();
  }, [meta?.page]);
  
  return {
    aboutUs,
    meta,
    setMeta,
    loading,
    updateAboutUs,
    Environtment,
  }
}

export default Services;