import PropTypes from 'prop-types';
const Image = (props) => {
  const {src} = props;

  return (
    <img src={src} alt='...' className="object-cover object-center w-full h-full"/>
  )
}

Image.propTypes = {
  src: PropTypes.string,
}

Image.defaultProps = {
  src: "",
}

export default Image