import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '../atoms';
import { useRef } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import {BsNewspaper} from 'react-icons/bs';
import {CgInsights} from 'react-icons/cg';
import { MdOutlineFeedback, MdConnectWithoutContact, MdApartment} from 'react-icons/md';
import {AiFillHome, AiOutlineDown} from 'react-icons/ai';
import { RiLayoutTopFill, RiCustomerService2Line, RiUser2Fill } from 'react-icons/ri';
const SubList = (props) => {
  const ref = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const {parent, child} = props;
  const [showChildren, setShowChildren] = useState(validateActive());
  function validateActive () {
    if(child?.length > 0) {
      if (child.find((item) => item.path === location.pathname)!== undefined){
        return true;
      } else {
        return false;
      }
    }else{
      return location.pathname === parent.path;
    }
  }
  const renderIcon = (params) => {
    switch(params){
      case 0:
        return <AiFillHome size={24}/>;
      case 1:
        return <RiLayoutTopFill size={24}/>;
      case 2:
        return <MdConnectWithoutContact size={25}/>;
      case 3:
        return <MdApartment size={24}/>;
      case 4:
        return <RiCustomerService2Line size={24}/>;
      case 5:
        return <CgInsights size={24} />
      case 6:
        return <BsNewspaper size={24}/>
      case 7:
        return <MdOutlineFeedback size={24}/>
      case 8:
        return <RiUser2Fill size={24} />
      default:
        break;
    }
  }
  const renderClasses = () => {
    if (child.length < 1){
      return `w-full flex items-center ${validateActive() ? 'bg-white text-dark' : 'bg-transparent'} text-left p-2 flex hover:bg-white/50 hover:text-dark`
    } else {
      return `w-full flex items-center ${validateActive() && !showChildren ? 'bg-white text-dark  ' : 'bg-transparent'} text-left p-2 flex hover:bg-white/50 hover:text-dark`
    }
  }
  return (
    <div className='w-full' ref={ref}>
      <Button 
        classes={renderClasses()} 
        handler={()=>{
          (child.length > 0) ? setShowChildren(!showChildren) : navigate(parent.path);
        }}>
        <i className='mr-4'>
          {renderIcon(parent.icon)}
        </i>
        {parent?.menu}
        {child?.length > 0 && (
          <i>
            <AiOutlineDown/>
          </i>
        )}
      </Button>
      {child && (
        <div className={`${showChildren ? 'block' : 'hidden'} w-full flex flex-col`}>
          {child?.map((item, index) => (
            <Button
              classes={`w-full py-2 pl-20 text-left ${item?.path === location.pathname ? 'bg-white text-dark' : 'bg-transparent'} hover:bg-white/50 hover:text-dark`}
              text={item?.menu}
              handler={()=>{
                navigate(item?.path);
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

SubList.propTypes = {
  parent: PropTypes.object,
  child: PropTypes.array
}

SubList.defaultProps = {
  parent: {},
  child: []
}

export default SubList;