import Loading from "../../templates/loading";
import PrivateTemplate from "../../templates/privateTemplates";
import Services from "./services";
import Screen from "./screen";
const AddedServices = () => {
  const {
    loading,
    meta,
    setMeta,
    dataServices,
    addAddedServices,
    deleteAddedServices,
    updateAddedServices,
  } = Services();
  return (
    <PrivateTemplate>
      <Loading display={loading} />
      <Screen
        meta={meta}
        setMeta={(e) => setMeta(e)}
        data={dataServices}
        insertHandler={(e) => addAddedServices(e)}
        deleteHandler={(e) => deleteAddedServices(e)}
        updateHandler={(e) => updateAddedServices(e)}
      />
    </PrivateTemplate>
  );
};

export default AddedServices;
