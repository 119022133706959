import { Navigate, Outlet} from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
  const {isLogin} = useSelector((state) => state.UserLogin);
  return (isLogin) ? (
    <Navigate replace to="/" />
  ) : (
    <Outlet />
  )
}

export default PublicRoute