import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import alertMessage from "../../../helpers/alertMessage";
import { GlobalDelete, GlobalGet, GlobalPost, GlobalPut } from "../../../helpers/fetcher";
import Environtment from '../../../constant/environtment.json';

const Services = () => {
  const {REACT_APP_API_URL} = Environtment;
  const { data } = useSelector((state) => state.UserLogin);
  const [headers] = useState({
    "user-token": data?.token,
  });
  const [dataNews, setDataNews] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    total_page: 0,
    total_result: 0,
    total_data: 0,
    limit: 5,
    max_page: 2,
  });
  const [loading, setLoading] = useState(false);

  const previousNumber = () => {
    const result1 = meta.limit * (meta.page - 1);
    const result = (result1 / meta.page) * meta.page;
    return Math.round(result);
  };

  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await GlobalGet({
        url: `${REACT_APP_API_URL}/news`,
        // params: meta,
      });
      if (response?.status === 200) {
        setLoading(false);
        setDataNews(response?.data);
      } else {
        setLoading(false);
        alertMessage("error", response?.message, "Retry", () => {});
      }
    } catch (err) {
      setLoading(false);
      alertMessage("network_error", err, "Retry", () =>
        window.location.reload()
      );
    }
  };

  const deleteNews = async (id) => {
    setLoading(true);
    try {
      const result = await GlobalDelete({
        url: `${REACT_APP_API_URL}/news/${id}`,
        headers: headers
      });
      if (result?.status === 200) {
        setLoading(false);
        alertMessage("success", result?.message, "Ok", () =>
          window.location.reload()
        );
      } else {
        setLoading(false);
        alertMessage("error", result?.message, "Retry", () => {});
      }
    } catch (err) {
      setLoading(false);
      alertMessage("error", err, "Retry", () => {});
    }
  };
  const updateNews = async(params) => {
    setLoading(true);
    try{
      let urlImage = '';
      if(typeof(params?.post_image) === 'object'){
        const resUploadImage = ({image: params?.post_image});
        urlImage = resUploadImage?.data?.url;
      }else{
        urlImage = params?.post_image;
      }
      const response = await GlobalPut({
        url : `${REACT_APP_API_URL}post/${params?.post_id}`,
        data : {
          post_title: params?.post_title,
          post_content: params?.post_content,
          status_release: params?.status_release,
          post_image: urlImage
        },
        headers: { ...headers, "Content-Type": "application/json" },
      })

      if(response?.status === 200){
        setLoading(false);
        alertMessage('success', response?.message, 'Ok', () => window.location.reload())
      }else{
        setLoading(false);
        alertMessage('error', response?.message, 'Retry', ()=>{});
      }
    }catch(err){
      setLoading(false);
      alertMessage('error', err, 'Retry', ()=>{});
    }
  }

  const addNews = async (params) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", params?.title);
      formData.append("image", params?.image);
      formData.append("description", params?.content);
      formData.append("status", params?.status_release);
      const result = await GlobalPost({
        url: `${REACT_APP_API_URL}/news`,
        data: formData,
        headers: { ...headers, "Content-Type": "multipart/form-data" },
      });
      if (result?.status === 201) {
        setLoading(false);
        alertMessage("success", result?.message, "Ok", () =>
          window.location.reload()
        );
      } else {
        setLoading(false);
        alertMessage("error", result?.message, "Retry", () => {});
      }
    } catch (err) {
      alertMessage("network_error", "Network Error", "Retry", () =>
        window.location.reload()
      );
    }
  };

  useEffect(() => {
    fetchNews();
  }, [meta?.page]);

  return {
    dataNews,
    loading,
    addNews,
    deleteNews,
    meta,
    setMeta,
    updateNews
  };
};

export default Services;
