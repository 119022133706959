import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "../../atoms";

const FormLogin = (props) => {
  const { onSubmit } = props;
  const [params, setParams] = useState({
    email: '',
    password: '', 
  });
  const [show, setShow] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('params', params);
    onSubmit(params);
  };
  return (
    <form onSubmit={handleSubmit}>
      <center>
        <h1 className="font-bold text-3xl">Login</h1>
      </center>
      <div className="my-6 flex-col w-full h-full">
        <label className="mb-2 font-semibold text-2xl">Email</label>
        <input
          type="email"
          required
          className="w-full border-2 rounded-md p-2"
          onChange={(e) => setParams({ ...params, email: e.target.value })}
        />
      </div>
      <div className="my-6 relative flex-col w-full h-full">
        <label className="mb-2 font-semibold text-2xl">Password</label>
        <input
          required
          type={show ? "text" : "password"}
          className="w-full border-2 rounded-md py-2 pl-2 pr-8"
          onChange={(e) => setParams({ ...params, password: e.target.value })}
        />
        <Button
          classes="p-2 absolute right-0 bg-transparent"
          handler={() => setShow(!show)}
        >
          {show ? (
            <FaEye className="my-1" size={20} />
          ) : (
            <FaEyeSlash className="my-1" size={20} />
          )}
        </Button>
      </div>
      <div className="my-6 flex-col w-full h-full">
        <Button classes="btn-login" text="Login" type="submit" />
      </div>
    </form>
  );
};

export default FormLogin;
