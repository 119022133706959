import listYear from "../../helpers/dataYear";
import PropTypes from 'prop-types';
const SelectYear = (props) => {
  const {classes, handler, value} = props;
  return (
    <select name="year" id="year" className={classes} value={value} onChange={(e)=>handler(e)}>
      <option value={null}>Pilih Tahun</option>
      {listYear(77).map((item, index) => (
        <option key={index} value={item}>{item}</option>
      ))}
    </select>
  )
}

listYear.propTypes = {
  classes: PropTypes.string,
  handler: PropTypes.func,
  value: PropTypes.string
}

listYear.defaultProps = {
  classes: 'w-full outline-none',
  handler: () => {},
  value: ''
}
export default SelectYear;