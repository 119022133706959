import { useEffect, useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import reactDom from "react-dom";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "../../modal";
import { Button } from "../../../atoms";

const ResizeCanvas = ({
  show,
  setShow,
  imageUrl,
  setImageUrl,
  resetFile,
  width,
  height,
  aspect,
  options,
}) => {
  const [srcImg, setSrcImg] = useState(null);
  const [image] = useState(useRef(HTMLImageElement));
  const [crop, setCrop] = useState({
    unit: aspect ? "%" : "px",
    width: aspect ? width : null,
    height: height || null,
    x: 0,
    y: 0,
    aspect: aspect || null,
  });
 
  useEffect(() => {
    setCrop({
      ...crop,
      x: 0,
      y: 0,
    });
    setShowOrigin(true);
  }, [show]);
  const [result, setResult] = useState(null);
  const [showOrigin, setShowOrigin] = useState(true);
 
  async function getCroppedImg() {
    const canvas = document.createElement('canvas');
    const scaleX = image.current.naturalWidth / image.current.width;
    const scaleY = image.current.naturalHeight / image.current.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image?.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width*scaleX,
      crop.height*scaleY,
    );
    canvas.toBlob(blob => {
      blob.name = options?.name;
      setResult(blob)
    }, options?.type, 1);
  }
  const handleSubmit = async () => {
    const myFile = new File([result], options?.type, {
      type: options?.type,
    });
    setImageUrl(myFile);
    setShow(false);
  };

  useEffect(() => {
    if (imageUrl != null && show) {
      setSrcImg(imageUrl);
    }
  }, [imageUrl]);

  return (
    <Modal open={show} setOpen={() => setShow(false)}>
      <div className="h-full">
        <div className="flex flex-row justify-between bg-white sticky-top w-full p-3">
          <div className="text-12">
            <span>Seuaikan Photo Profile</span>
          </div>
          <div className="text-20" onClick={() => setShow(false)}>
            <span>X</span>
          </div>
        </div>
        <div className="bg-white relative w-full overflow-y-scroll scroll-green">
          <div className="flex flex-col justify-center items-center h-full">
            {!result && (
               <ReactCrop
                className={`w-full h-full object-contain`}
                crop={crop}
                aspect={aspect}
                onChange={(c) => setCrop(c)}
             >
               <img
                 src={srcImg}
                 className={`w-full h-full object-contain`}
                 alt="..."
                 ref={image}
               />
             </ReactCrop>
              // <ReactCrop
              //   className="w-full h-full border"
              //   src={srcImg}
              //   onImageLoaded={setImage}
              //   crop={crop}
              //   onChange={setCrop}
              // />
            )}
          </div>
          {result && (
            <img className="w-full h-full" src={window.URL.createObjectURL(result)} alt="..." />
          )}
          <div className="flex flex-row justify-between mt-auto container  mx-auto shadow-lg rounded-md w-full p-2">
            <div className="">
              <button
                onClick={() => setShow(false)}
                className="border-red-500 text-red-500 border rounded-md p-2"
              >
                Batalkan
              </button>
            </div>
            <div>
              {!result && crop && (
                <Button
                  handler={() => getCroppedImg()}
                  classes="border-blue-500 text-blue-500 border rounded-md p-2"
                >
                  Sesuaikan
                </Button>
              )}
              {result && (
                <Button
                  handler={() => handleSubmit()}
                  classes="ml-3 border-blueWebsite border p-2 rounded-md"
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ResizeCanvas.defaultProps = {
  show: false,
  setShow: () => {},
  imageUrl: null,
  setImageUrl: () => {},
  resetFile: () => {},
  width: null,
  height: null,
  aspect: null,
};
export default ResizeCanvas;
