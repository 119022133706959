import PrivateTemplate from "../../templates/privateTemplates";
import Portofolio from "../../../constant/portofolio.json";
import Screen from "./screen";
import Services from "./services";
import Loading from "../../templates/loading";

const Dashboard = () => {
  const { loading, countData} = Services();
  return (
    <PrivateTemplate>
      <Loading display={loading} />
      <Screen countData={countData} />
    </PrivateTemplate>
  );
};

export default Dashboard;
