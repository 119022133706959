import { useState } from "react";
import UploadResize from "../../../molecules/custom/upload-image/uploadResize";
import Modal from "../../../molecules/modal";
import { AddImage } from "../../../../assets/images";
import { Button, Input, JoditTextEditor } from "../../../atoms";
import JodithConfig from "../../../../helpers/jodithConfig";

const FormAdd = (props) => {
  const { insertHandler, display, handlerClose } = props;
  const [config] = useState(JodithConfig(true));
  const [data, setData] = useState({
    image: null,
    title: "",
    description: "",
  });
  const submitForm = (e) => {
    e.preventDefault();
    console.log('hmmm');
    insertHandler(data);
  };

  return (
    <Modal open={display} showButton={true} handlerClose={() => handlerClose()}>
      <form onSubmit={submitForm}>
        <div className="w-[800px] h-fit flex p-4">
          <div className="w-1/4">
            <div className="w-full h-[200px]">
              <UploadResize
                imgSrc={data?.image || AddImage}
                setImageSrc={(e) => setData({ ...data, image: e })}
                aspect={1/1}
              />
            </div>
          </div>
          <div className="w-3/4 flex-col p-4">
            <div className="mb-2">
              <label>Title</label>
              <Input
                classes={"border border-dark w-full p-2 rounded-md"}
                classesInput={"w-full outline-none"}
                value={data.title}
                handler={(e) => setData({ ...data, title: e.target.value })}
              />
            </div>
            <div className="mb-2">
              <label>Content</label>
              <JoditTextEditor
                config={config}
                content={data.description}
                setContent={(e) => setData({ ...data, description: e })}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <Button
            classes={"rounded-md bg-dark text-white p-2 w-full"}
            text={"Submit"}
            type={"submit"}
          />
        </div>
      </form>
    </Modal>
  );
};

export default FormAdd;
