import { useState } from "react";
import { Button } from "../../atoms";
import Pagination from "../../molecules/pagination";
import FormAdd from "../../organisms/form/added-services/formAdd";
import TableAddedServices from "../../organisms/table/tbAddedServices";
const Screen = (props) => {
  const { meta, setMeta, data, insertHandler, deleteHandler, updateHandler } = props;
  const [showAdd, setShowAdd] = useState(false);
  return (
    <main className="container-content">
      <div className="w-full h-fit">
        <div className="flex justify-between mb-2">
          <div>
            <span className="text-3xl">Data Add Services</span>
          </div>
          <Button
            text={"Add Data"}
            classes="text-white p-2 bg-dark rounded-sm hover:bg-dark/50"
            handler={() => setShowAdd(true)}
          />
          {/* table */}
        </div>
        <TableAddedServices
          data={data} 
          deleteHandler={deleteHandler}
          updateHandler={(e) => updateHandler(e)}
        />
        <FormAdd
          display={showAdd}
          handlerClose={() => setShowAdd(false)}
          insertHandler={(e) => insertHandler(e)}
        />
        <div className="my-3 flex justify-between items-center px-3">
          <span>Showing 1 - {meta.total_data} data</span>
          <Pagination
            maxPage={meta.max_page}
            totalData={meta.limit}
            setRows={(value) => setMeta({ ...meta, limit: value, page: 1 })}
            active={meta?.page}
            total={meta?.total_page}
            handler={(value) => setMeta({ ...meta, page: value })}
          />
        </div>
      </div>
    </main>
  );
};

export default Screen;
