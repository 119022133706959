import { Button } from '../atoms';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
const ButtonPagination = (props) => {
  const {
    text,
    active,
    handler,
    disabled,
  } = props;

  return (
    <Button
      text={text}
      disabled={disabled}
      handler={() => handler()}
      classes={`p-2 w-8 h-8 font-bold text-sm ${active ? 'border-blueWebsite' : 'border-gray-300'} border-2 text-blueWebsite mx-1 text-xs flex justify-center items-center`}
    />
  );
};

const Pagination = (props) => {
  const {
    active,
    total,
    handler,
    disabled,
    maxPage,
  } = props;

  const componentButtonPagination = (index, activeParams) => (
    <div key={index} className="inline-block">
      <ButtonPagination
        text={`${index}`}
        disabled={disabled || false}
        active={activeParams === index}
        handler={() => handler(index)}
      />
    </div>
  );
  const loop = (activeParams) => {
    const pageComponent = [];
    const tempTotal = maxPage ? maxPage * maxPage : total;
    if (total <= tempTotal) {
      // ini function aslinya
      for (let i = 1; i <= total; i += 1) {
        pageComponent.push(componentButtonPagination(i, activeParams));
      }
    } else {
      const limit = activeParams - (maxPage || 0);
      if (limit <= 0) {
        for (let i = 1; i <= tempTotal + 1; i += 1) {
          pageComponent.push(componentButtonPagination(i, activeParams));
        }
      } else if (activeParams >= total - (maxPage || 0)) {
        for (let i = activeParams - (maxPage || 0); i <= total; i += 1) {
          pageComponent.push(componentButtonPagination(i, activeParams));
        }
      } else {
        const loop1 = activeParams - (maxPage || 0);
        const loop2 = activeParams + (maxPage || 0);
        if (maxPage) {
          for (let i = loop1; i <= activeParams; i += 1) {
            pageComponent.push(componentButtonPagination(i, activeParams));
          }
          for (let i = activeParams + 1; i <= loop2; i += 1) {
            pageComponent.push(componentButtonPagination(i, activeParams));
          }
        }
      }
    }

    return pageComponent;
  };

  return (
    <div className="flex justify-end items-center">
      <Button
        classes={`p-2 flex ${active === 1 ? 'text-gray2' : 'text-blueWebsite'}`}
        disabled={active === 1}
        handler={() => handler(1)}
        text={'First'}
      />
      <Button
        classes={`p-2 w-8 h-8 flex items-center justify-center ${active < 2 ? 'bg-gray-300 text-white' : 'bg-transparent border-gray-300 border-2'}`}
        disabled={active < 2}
        handler={() => handler(active - 1)}
      >
        {active < 2 ? (
          <BsChevronLeft/>
        ):(
          <BsChevronLeft fill='#12385C'/>
        )}
      </Button>
      { loop(active)}
      <Button
        classes={`p-2 w-8 h-8 flex items-center justify-center ${active >= total ? 'bg-gray-300 text-white' : 'bg-transparent border-gray-300 border-2'}`}
        disabled={active >= total}
        handler={() => handler(active + 1)}
      >
         {active >= total ? (
          <BsChevronRight/>
         ):(
          <BsChevronRight fill='#12385C'/>
         )}
         {/* <img src={`${active >= total ? '/assets/images/icon-arrow-white.svg': '/assets/images/icon-arrow.svg'}`} alt='...'/> */}
      </Button>
      <Button
        classes={`p-2 flex ${active >= total ? 'text-gray2' : 'text-blueWebsite'}`}
        disabled={active >= total}
        handler={() => handler(total)}
        text={'Last'}
      />
    </div>
  );
};

Pagination.defaultProps = {
  handler: () => { },
  disabled: false,
  listRange: [],
  setRows: () => { },
  totalData: 0,
  maxPage: null,
};

export default Pagination;
