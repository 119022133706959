import Swal from "sweetalert2"
import { NetworError } from "../assets/images"
const alertMessage = (type, message, txtButton, cbConfirmed) => {
  switch(type){
    case 'network_error':
      return Swal.fire({
        text: 'Network Error',
        title:'Error',
        confirmButtonText: 'Retry',
        iconHtml: `<img src=${NetworError}>`,
        customClass: {
          icon: 'border-0'
        }
      })
    case 'success':
      return Swal.fire({
        text: message || 'Success',
        icon: 'success',
        confirmButtonText:'Ok'
      }).then((res) => {
        if(res.isConfirmed){
          return cbConfirmed();
        }
      })
    case 'error':
      const msgError = message === "Unauthorized" ? "Your time is out" : "Error"; 
      const resMsg = message === "Unauthorized" ? "Please re-Login and try again" : message; 
      return Swal.fire({
        title: msgError || 'Error',
        text: resMsg || 'Failed',
        icon: 'error',
        confirmButtonText: txtButton || 'Retry'
      }).then((result) => {
        if (result.isConfirmed) {
          return cbConfirmed();
        }
    });
    case 'confirmation':
      return Swal.fire({
        title: 'Delete Section',
        text: "Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'bg-red-500',
          cancelButton: 'border-gray-400 border text-gray-400 bg-transparent'
        }
    }).then((result) => {
        if (result.isConfirmed) {
          return cbConfirmed();
        }
    });

    default:
      break;
  }
}

export default alertMessage;