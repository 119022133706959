import PropTypes from 'prop-types';
import Navbar from './navbar';
import { useSelector } from 'react-redux';
const Content = (props) => {
  const {isLogin, data} = useSelector((state)=>state.UserLogin);
  const {children, sideBar, navbar, navbarClasses, contentClasses} = props;
  return (
    <div className={`${contentClasses? contentClasses : 'h-full'}`}>
      <Navbar classes={navbarClasses} isLogin={isLogin}/>
      <main className='h-full'>
        {children}
      </main>
    </div>
  )
}

Content.propTypes = {
  navbar: PropTypes.bool,
  navbarClasses: PropTypes.string,
  sidebar: PropTypes.bool,
  children: PropTypes.elementType
}

export default Content;