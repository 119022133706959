import PropTypes from 'prop-types'; 
const TextArea = (props) => {
  const {limit, value, handler, placeHolder, classes, disabled, required} = props;
  const handleChange = (e) => {
    if(limit){
      if(e.target.value.length > limit){
        return false;
      }else{
        handler(e.target.value);
      }
    }else{
      handler(e.target.value);
    }
  }
  return (
    <div className={ classes || `w-full h-full rounded-m p-6 border-blueWebsite border`}>
      <div className="flex h-full flex-col">
        <textarea
          required={required} 
          placeholder={placeHolder} 
          disabled={disabled}
          onChange={(e) => handleChange(e)} 
          className={`outline-none w-full h-full border-none`}
          value={value}
        />
        {limit && (
          <div className="w-full p-2 h- mb-0">
            <span className="text-gray-500 float-right">{`${value.length} / ${limit}`}</span>
          </div>
        )}
      </div>
    </div>
  )
}

TextArea.propTypes = {
  limit: PropTypes.number,
  value: PropTypes.string,
  handler: PropTypes.func,
  placeHolder: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
}

TextArea.defaultProps = {
  limit: null,
  value: '',
  handler: () => {},
  placeHolder: '',
  classes: '',
  disabled: false,
  required: false
}

export default TextArea;