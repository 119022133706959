import { useEffect, useState } from "react";
import JodithConfig from "../../../../helpers/jodithConfig";
import { Input, JoditTextEditor, Button } from "../../../atoms";

const FormEditAbout = (props) => {
  const { handlerUpdate, data} = props;
  const [initialData, setInitialData] = useState({
    id: "",
    title: "",
    sub_title: "",
    description: "",
    type: "",
  });
  const [config] = useState(JodithConfig(true));

  const updateAction = (e) => {
    e.preventDefault();
    handlerUpdate(initialData);
  };
  useEffect(() => {
    if (data) {
      setInitialData({
        id: data?.id,
        title: data?.title,
        sub_title: data?.sub_title,
        description: data?.description,
        type: data?.type,
      });
    }
  }, [data]);
  return (
    <form onSubmit={updateAction} className="flex flex-col">
      <div className="mb-2">
        <label>Title</label>
        <Input
          classes={"border border-dark w-full p-2 rounded-md"}
          classesInput={"w-full outline-none"}
          value={initialData?.title}
          handler={(e) =>
            setInitialData({ ...initialData, title: e.target.value })
          }
        />
      </div>
      <div className="mb-2">
        <label>Sub Title</label>
        <Input
          classes={"border border-dark w-full p-2 rounded-md"}
          classesInput={"w-full outline-none"}
          value={initialData?.sub_title}
          handler={(e) =>
            setInitialData({ ...initialData, sub_title: e.target.value })
          }
        />
      </div>
      <JoditTextEditor
        config={config}
        content={initialData?.description}
        setContent={(e) => setInitialData({ ...initialData, description: e })}
      />
      <div className="flex mt-2 flex-col w-full">
        <Button
          classes={"rounded-md bg-dark text-white p-2 w-full"}
          text={"Save"}
          type={"submit"}
        />
      </div>
    </form>
  );
};

export default FormEditAbout;
