import PropTypes from "prop-types";
const RadioButton = (props) => {
  const {
    classes,
    label,
    name,
    handler,
    value,
    defaultValue,
  } = props;
  return (
    <div className="form-check my-1">
      <input className={classes || "form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"} 
        type="radio" 
        name={name || 'radioButton'} 
        value={value}
        checked={defaultValue === value}
        onChange={(e) => handler(e.target.value) }
      />
      <label className="form-check-label inline-block text-gray-800">
        {label || 'label'}
      </label>
  </div>
  )
}

RadioButton.propTypes = {
  classes: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  handler: PropTypes.func
}

RadioButton.defaultProps = {
  classes: '',
  label: '',
  value: '',
  name: '',
  handler: ()=>{}
}
export default RadioButton;