import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import Environtment from '../../../constant/environtment.json';
import alertMessage from '../../../helpers/alertMessage';
import { GlobalDelete, GlobalGet, GlobalPost, GlobalPut } from '../../../helpers/fetcher';
const Services = () => {
  const {REACT_APP_API_URL} = Environtment;
  const { data } = useSelector((state) => state.UserLogin);
  const [headers] = useState({
    "user-token": data?.token,
  });
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    total_page: 0,
    total_result: 0,
    total_data: 0,
    limit: 10,
    max_page: 2,
  });
  const updatePartner = async(params) => {
    setLoading(true);
    try{
      const formData = new FormData();
      formData.append("name", params?.name);
      if(typeof(params?.image) === 'object'){
        formData.append("image", params?.image);
      }

      const response = await GlobalPut({
        url : `${REACT_APP_API_URL}/partners/${params?.id}`,
        data : formData,
        headers: { ...headers, "Content-Type": "multipart/form-data" },
      })

      if(response?.status === 200){
        setLoading(false);
        alertMessage('success', response?.message, 'Ok', () => window.location.reload())
      }else{
        setLoading(false);
        alertMessage('error', response?.message, 'Retry', ()=>{});
      }
    }catch(err){
      setLoading(false);
      alertMessage('error', err, 'Retry', ()=>{});
    }
  }
  const addParner = async (params) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", params?.title);
      formData.append("image", params?.image);
        const result = await GlobalPost({
          url: `${REACT_APP_API_URL}/partners/add`,
          data: formData,
          headers: { ...headers, "Content-Type": "multipart/form-data" },
        });
        if (result?.status === 201) {
          setLoading(false);
          alertMessage("success", result?.message, "Ok", () =>
            window.location.reload()
          );
        } else {
          setLoading(false);
          alertMessage("error", result?.message, "Retry", () => {});
        }
    } catch (err) {
      alertMessage("network_error", "Network Error", "Retry", () =>
        window.location.reload()
      );
    }
  };

  const fetchPartners = async() => {
    setLoading(true);
    try {
      const response = await GlobalGet({
        url: `${REACT_APP_API_URL}/partners`,
        // params: meta
      });
      if(response?.status === 200) {
        setPartners(response?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setPartners([]);
        alertMessage('error', response?.message, 'Retry', ()=>{});
      }
    }catch(err){
      setLoading(false);
      alertMessage('error', err, 'Retry', ()=>{});
    }
  }

  const deletePartner = async (id) => {
    setLoading(true);
    try {
      const result = await GlobalDelete({
        url: `${REACT_APP_API_URL}/partners/${id}`,
        headers: headers
      });
      if (result?.status === 200) {
        setLoading(false);
        alertMessage("success", result?.message, "Ok", () =>
          window.location.reload()
        );
      } else {
        setLoading(false);
        alertMessage("error", result?.message, "Retry", () => {});
      }
    } catch (err) {
      setLoading(false);
      alertMessage("error", err, "Retry", () => {});
    }
  };

  useEffect(()=>{
    fetchPartners();
  }, [meta?.page]);
  
  return {
    partners,
    meta,
    setMeta,
    addParner,
    loading,
    deletePartner,
    updatePartner,
    Environtment,
  }
}

export default Services;