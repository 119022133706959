import { useState } from 'react';
import { Button } from '../../atoms';
import { FormAdd, FormEdit } from '../../organisms/form/ourBusiness';
import Pagination from '../../molecules/pagination';
import TablePartners from '../../organisms/table/tbPartners';
import '../style.css';

const Screen = (props) => {
  const {data, meta, setMeta, insertHandler, deleteHandler, updateHandler, environtment} = props;
  const [showAdd, setShowAdd] = useState(false);

  return (
    <main className="container-content">
      <div className="w-full h-fit">
      <div className="flex justify-between mb-2">
        <div>
          <span className="text-3xl">Data Post</span>
        </div>
        <Button
          text={'Add Partner'}
          classes="text-white p-2 bg-dark rounded-sm hover:bg-dark/50"
          handler={() => setShowAdd(true)}
        />
      </div>
      <TablePartners
        environtment={environtment}
        data={data}
        updateHandler={(e) => updateHandler(e)}
        deleteHandler={(e) => deleteHandler(e)}
      />
      </div>
      <FormAdd
        display={showAdd}
        handlerClose={() => setShowAdd(false)}
        insertHandler={(e) => insertHandler(e)}
      />
      {/* <div className="my-3 flex justify-between items-center px-3">
      <span>Showing {meta?.page} - {meta.total_data} data</span>
        <Pagination
          maxPage={meta.max_page}
          totalData={meta.limit}
          setRows={(value) => setMeta({ ...meta, limit: value, page: 1 })}
          active={meta?.page}
          total={meta?.total_page}
          handler={(value) => setMeta({ ...meta, page: value })}
        />
      </div> */}
    </main>
  )
}

export default Screen;