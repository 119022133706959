import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Button } from "../atoms";
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {CgProfile} from "react-icons/cg";
import { useRef } from "react";
import { UserLogout } from "../../helpers/redux/actions/authentication";
// import { useSelector } from 'react-redux';

const Navbar = (props) => {
  const ref = useRef();
  const { isLogin, classes } = props;
  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);

  function useOutsideAlerter(paramsRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDisplay(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [paramsRef]);
  }
  useOutsideAlerter(ref);
  return (
    <nav className={classes || "w-full bg-white shadow-md min-h-10"}>
      {isLogin && (
        <div className="flex items-center h-full w-full">
          <div className="w-full">
            <div
              onClick={() => {
                setDisplay(!display);
              }}
              ref={ref}
              className="relative items-center float-right w-fit mr-3 mt-1 cursor-pointer"
            >
              <div className="flex items-center">
                <div className="w-10 h-10 rounded-full shadow-md p-2">
                  <CgProfile className="h-full w-full"/>
                </div>
                <AiFillCaretDown/>
              </div>
              <div
                className={`${!display && "hidden"} 
          absolute origin-top-right right-0 bg-white p-3 shadow-md mt-2`}
              >
                <Button
                  classes="flex bg-transparent items-center"
                  handler={() => dispatch(UserLogout())}
                >
                  <RiLogoutBoxRLine />
                  <span className="ml-2">Logout</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  profile: PropTypes.any,
  classes: PropTypes.string,
};

export default Navbar;
