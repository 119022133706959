const initialState = {
  data: [],
  loading: false,
};
export const GetOpportunities = (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_FREE_TRADE_AREA_SUCCESS":
      return {
        loading: false,
        data: action.payload,
      };
    case "GET_FREE_TRADE_AREA_FAILED":
      return {
        ...state,
        loading: false,
        data: [],
      };
    case "GET_IA_CEPA_SUCCESS":
      return {
        loading: false,
        data: action.payload,
      };
    case "GET_IA_CEPA_FAILED":
      return {
        ...state,
        loading: false,
        data: [],
      };
    case "GET_INDUSTRIAL_SUCCESS":
    case "GET_INDUSTRIAL_FAILED":
    case "GET_SEZS_SUCCESS":
    case "GET_SEZS_FAILED":
    default:
      return state;
  }
};
