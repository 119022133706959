const initialState = {
  data: {}
}
const GetTemporaryData = (state=initialState, action) => {
  switch(action.type){
    case "SET_TEMPORARY_DATA_WHY_INDONESIA":
      return {
        ...state,
        data: action.payload
      }
    default:
      return state;
  }
}

export default GetTemporaryData;