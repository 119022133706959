import PropTypes from 'prop-types';
const SelectBar = (props)=> {
  const {
    data,
    defaultChoose,
    handler,
    value,
  } = props;
  return (
    <div className='rounded-md border border-gray-400 p-2' placeholder={defaultChoose}>
      <select value={value} className='w-full outline-none' onChange={(e)=>handler(e)}>
        <option value={null} >Pilih</option>
        {data.map((item, index)=>(
          <option key={index} value={item?.id}>{item?.name}</option>
        ))}
      </select>
    </div>
  )
}

SelectBar.propTypes = {
  data: PropTypes.array,
  defaultChoose: PropTypes.string,
  handler: PropTypes.func
}

SelectBar.defaultProps={
  data: [],
  defaultChoose: PropTypes.string,
  handler: ()=>{}
}

export default SelectBar;