import { useEffect, useState } from "react";
import UploadResize from "../../../molecules/custom/upload-image/uploadResize";
import Modal from "../../../molecules/modal";
import { AddImage } from "../../../../assets/images";
import { Button, Input, JoditTextEditor } from "../../../atoms";
import JodithConfig from "../../../../helpers/jodithConfig";

const FormEdit = (props) => {
  const { updateHandler, display, handlerClose, initialData } = props;
  const [config] = useState(JodithConfig(true));
  const [data, setData] = useState({
    post_id: null,
    post_image: null,
    post_title: "",
    post_content: "",
  });
  const releasePost = () => {
    updateHandler({ ...data, status_release: "Release" });
  };

  const draftPost = () => {
    updateHandler({ ...data, status_release: "Draft" });
  };

  useEffect(() => {
    if (display) {
      console.log("initialData", initialData);
      setData(initialData);
    }
  }, [display, initialData]);

  return (
    <Modal open={display} showButton={true} handlerClose={() => handlerClose()}>
      <div className="w-[800px] h-fit flex p-4">
        <div className="w-1/4">
          <div className="w-full h-[200px]">
            <UploadResize
              imgSrc={data?.image || AddImage}
              aspect={16 / 9}
              handler={(e) => setData({ ...data, image: e })}
            />
          </div>
        </div>
        <div className="w-3/4 flex-col p-4">
          <div className="mb-2">
            <label>Title</label>
            <Input
              classes={"border border-dark w-full p-2 rounded-md"}
              classesInput={"w-full outline-none"}
              value={data.post_title}
              handler={(e) => setData({ ...data, post_title: e.target.value })}
            />
          </div>
          <div className="mb-2">
            <label>Content</label>
            <JoditTextEditor
              config={config}
              content={data.post_content}
              setContent={(e) => setData({ ...data, post_content: e })}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <Button
          classes={"rounded-md bg-dark text-white p-2 w-full"}
          text={"Publish"}
          handler={() => releasePost()}
        />
        <Button
          classes={
            "rounded-md bg-transparent hover:bg-dark/50 hover:text-white text-dark border border-dark mt-2 p-2 w-full"
          }
          text={"Save As Draft"}
          handler={() => draftPost()}
        />
      </div>
    </Modal>
  );
};

export default FormEdit;
