import { combineReducers } from "redux";
import { UserLogin, UserChangePassword } from "./authentication";
import { UpdateOurServices, GetOurServices } from "./ourServicesReducers";
import { UpdateAboutUs, GetAboutUs } from "./aboutUs";
import { GetInvestmentProcedures, UpdateInvestmentProcedures } from "./investmentProcedureReducers";
import { GetOpportunities } from "./opportunities";
import GetSocialMedia from "./socialMedia";
import GetFooter from "./footer";
import GetTemporaryData from "./temporaryData";
const reducers = combineReducers(
  {
    GetFooter,
    GetSocialMedia,
    GetOpportunities,
    UserLogin,
    GetAboutUs,
    UpdateAboutUs,
    UpdateOurServices,
    GetOurServices,
    GetInvestmentProcedures,
    UpdateInvestmentProcedures,
    UserChangePassword,
    GetTemporaryData
  }
);

export default reducers;