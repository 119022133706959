import Navbar from "../../templates/navbar";
import Screen from "./screen";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {UserLogin} from "../../../helpers/redux/actions/authentication";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoding] = useState(false);

  const processLogin = (e) => {
    dispatch(UserLogin(e, setLoding, navigate));
  }
  return (
    <div className="h-screen">
      <Navbar classes="w-full bg-white shadow-md h-10 fixed top-0"/>
        <Screen
          onSubmit = {(e) => processLogin(e)}
        />
    </div>
  )
}

export default Login;