import { Title, Description } from "../atoms";
import PropTypes from "prop-types";
import "./component.css";

const Card = (props) => {
  const { title, desc, variant, icon, handler } = props;
  return (
    <div className={variant} onClick={()=> handler && handler()}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <Title text={title} />
          <Description text={desc} />
        </div>
        <div>
          {icon && icon}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.any,
};

Card.defaultProps = {
  title: "",
  desc: "",
  variant: "card-dark",
  icon: null,
};

export default Card;
