import { useState } from "react";
import { GlobalDelete, GlobalGet, GlobalPost, GlobalPut } from "../../../../helpers/fetcher";
import Environtment from "../../../../constant/environtment.json";
import { useSelector } from "react-redux";
import alertMessage from "../../../../helpers/alertMessage";

const Services = () => {
  const [dataBillPayment, setDataBillPayment] = useState([]);
  const { data } = useSelector((state) => state.UserLogin);
  const [loading, setLoading] = useState(false);
  const { REACT_APP_API_URL } = Environtment;
  const [headers] = useState({
    "user-token": data?.token,
  });
  const [meta, setMeta] = useState({
    page: 1,
    total_page: 0,
    total_result: 0,
    total_data: 0,
    limit: 10,
    max_page: 2,
  });

  const fetchDataBillPayment = async () => {
    setLoading(true);
    try {
      const response = await GlobalGet({
        url: `${REACT_APP_API_URL}/verification-id`,
      });
      if (response?.status === 200) {
        setLoading(false);
        setDataBillPayment(response?.data);
      } else {
        setLoading(false);
        alertMessage("error", response?.message, "Retry", () => {});
      }
    } catch (err) {
      setLoading(false);
      alertMessage("error", err, "Retry", () => {});
    }
  };

  const addBillPayment = async (params) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", params?.title);
      formData.append("image", params?.image);
      const result = await GlobalPost({
        url: `${REACT_APP_API_URL}/verification-id`,
        data: formData,
        headers: { ...headers, "Content-Type": "multipart/form-data" },
      });
      if (result?.status === 201) {
        setLoading(false);
        alertMessage("success", result?.message, "Ok", () =>
          window.location.reload()
        );
      } else {
        setLoading(false);
        alertMessage("error", result?.message, "Retry", () => {});
      }
    } catch (err) {
      alertMessage("network_error", "Network Error", "Retry", () =>
        window.location.reload()
      );
    }
  };

  const updateVerifyId = async (params) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", params?.title);
      if(typeof(params?.image) === 'object'){
        formData.append("image", params?.image);
      }
      const result = await GlobalPut({
        url: `${REACT_APP_API_URL}/verification-id/${params?.id}`,
        data: formData,
        headers: { ...headers, "Content-Type": "multipart/form-data" },
      });
      if (result?.status === 200) {
        setLoading(false);
        alertMessage("success", result?.message, "Ok", () =>
          window.location.reload()
        );
      } else {
        setLoading(false);
        alertMessage("error", result?.message, "Retry", () => {});
      }
    } catch (err) {
      alertMessage("network_error", "Network Error", "Retry", () =>
        window.location.reload()
      );
    }
  };
  const deleteBillPayment = async (id) => {
    setLoading(true);
    try {
      const result = await GlobalDelete({
        url: `${REACT_APP_API_URL}/verification-id/${id}`,
        headers: headers
      });
      if (result?.status === 200) {
        setLoading(false);
        alertMessage("success", result?.message, "Ok", () =>
          window.location.reload()
        );
      } else {
        setLoading(false);
        alertMessage("error", result?.message, "Retry", () => {});
      }
    } catch (err) {
      setLoading(false);
      alertMessage("error", err, "Retry", () => {});
    }
  };

  useState(() => {
    fetchDataBillPayment();
  }, []);
  
  return {
    dataBillPayment,
    loading,
    meta,
    setMeta,
    addBillPayment,
    deleteBillPayment,
    updateVerifyId,
  };
};

export default Services;
