import PrivateTemplate from "../../../templates/privateTemplates";
import Services from "./services";
import Screen from "./screen";
import Loading from "../../../templates/loading";
const VerifyId = () => {
  const {
    loading,
    dataBillPayment,
    addBillPayment,
    deleteBillPayment,
    meta,
    setMeta,
    updateVerifyId,
  } = Services();
  return (
    <PrivateTemplate>
      <Loading display={loading}/>
      <Screen
        meta={meta}
        setMeta={(e) => setMeta(e)}
        data={dataBillPayment}
        insertHandler={(e) => addBillPayment(e)}
        deleteHandler={(e) => deleteBillPayment(e)}
        updateHandler={(e) => updateVerifyId(e)}
      />
    </PrivateTemplate>
  )
}

export default VerifyId;