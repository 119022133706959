import { Logo } from "../../assets/images";
import { Button } from "../atoms";
import Menu from '../../constant/menu.json';
import SubList from "../molecules/subList";
import './style.css';
import 'react-pro-sidebar/dist/css/styles.css';
const SideBar = () => {
  return(
    <aside className="sidebar">
      <Button classes="btn-logo">
        <img src={Logo} alt='...' className="w-full"/>
      </Button>
      <div className="sidebar-menu">
        {Menu.map((item, index) => (
          <SubList
            parent={Menu[index]}
            child={Menu[index].children}
          />
        ))}
      </div>
    </aside>
  )
}

export default SideBar;