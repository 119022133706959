import Modal from "../molecules/modal";
import { Icon, IconLoading } from "../../assets/images";

const Loading = (props) => {
  const {display} = props;
  return (
    <Modal open={display} zIndex={'z-30'}>
      <div className="w-[500px] h-fit flex items-center justify-center p-2">
        <div className="animate-bounce">
          <span className="text-5xl text-dark whitespace-nowrap">Loading ...</span>
        </div>
      </div>
    </Modal>
  )
}

export default Loading;