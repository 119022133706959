const initialState = {
  data : [],
  loading : false
}

export const UpdateOurServices = (state = initialState, action = {}) => {
  switch(action.type){
      case 'UPDATE_CONSULTATION_BOOK_SUCCESS':
          return{
              loading : false,
              data : action.payload
          }
      case 'UPDATE_CONSULTATION_BOOK_FAILED':
          return{
              ...state,
              loading : false,
              data : []
          }
      default :
          return state
  }
}

export const GetOurServices = (state = initialState, action = {}) => {
  switch(action.type){
    case 'GET_CONSULTATION_BOOK_SUCCESS':
      return {
        loading: false,
        data: action.payload
      }
    case 'GET_CONSULTATION_BOOL_FAILED':
      return {
        ...state,
        loading: false,
        data: []
      }
    default :
    return state;
  }
}