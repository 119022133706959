import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import configureStore from './helpers/redux/store';
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router, 
  Route, 
  Routes
} from 'react-router-dom';
import Login from './components/pages/login';
import Partners from './components/pages/partners';
import Dashboard from './components/pages/dashboard';
import News from './components/pages/news';
import AddedServices from './components/pages/added-services';
import AboutUs from './components/pages/about-us';
import UseCase from './components/pages/use-case';
import WhyShouldPartner from './components/pages/why-should-partner';
import PaymentServices from './components/pages/our-services/payment-services';
import {PublicRoute, PrivateRoute} from './components/templates/Route';
import BillPayment from './components/pages/our-services/bill-payment';
import VerifyId from './components/pages/our-services/verify-id';

const {store, persistor} = configureStore();

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/about-us' element={<PrivateRoute/>}>
          <Route exact path='/about-us' element={<AboutUs/>}/>
        </Route>
        <Route exact path='/partners' element={<PrivateRoute/>}>
          <Route exact path='/partners' element={<Partners/>}/>
        </Route>
        <Route exact path='/added-services' element={<PrivateRoute/>}>
          <Route exact path='/added-services' element={<AddedServices/>}/>
        </Route>
        <Route exact path='/use-case' element={<PrivateRoute/>}>
          <Route exact path='/use-case' element={<UseCase/>}/>
        </Route>
        <Route exact path='/news' element={<PrivateRoute/>}>
          <Route exact path='/news' element={<News/>}/>
        </Route>
         <Route exact path='/' element={<PrivateRoute/>}>
          <Route exact path='/' element={<Dashboard/>}/>
        </Route>
        <Route exact path='/our-services/payment-services' element={<PrivateRoute/>}>
          <Route exact path='/our-services/payment-services' element={<PaymentServices/>}/>
        </Route>
        <Route exact path='/our-services/bill-payment' element={<PrivateRoute/>}>
          <Route exact path='/our-services/bill-payment' element={<BillPayment/>}/>
        </Route>
        <Route exact path='/our-services/verification-id' element={<PrivateRoute/>}>
          <Route exact path='/our-services/verification-id' element={<VerifyId/>}/>
        </Route>
        <Route exact path='/why-you-should-partner' element={<PrivateRoute />}>
          <Route exact path='/why-you-should-partner' element={<WhyShouldPartner />}/>
        </Route>
        <Route exact path='/' element={<PublicRoute/>}>
          <Route exact path='/login' element={<Login/>}/>
        </Route>
    </Routes>
  </Router>
  );
}
export default function App() {
  return(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  )
}
