const JodithConfig = (edited) => {
  return {
    readonly: !edited,
    toolbar: edited,
    height: 300,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
  };
};

export default JodithConfig
