import Loading from "../../templates/loading";
import PrivateTemplate from "../../templates/privateTemplates";
import Screen from "./screen";
import Services from "./services";
const News = () => {
  const { dataNews, loading, addNews, deleteNews, updateNews, meta, setMeta } = Services();

  return (
    <PrivateTemplate>
      <Loading 
        display={loading}
      />
      <Screen
        meta={meta}
        setMeta={(e) => setMeta(e)}
        data={dataNews}
        deleteHandler={(e) => deleteNews(e)}
        insertHandler={(e) => addNews(e)}
        updateHandler={(e) => updateNews(e)}
      />
    </PrivateTemplate>
  );
};

export default News;
