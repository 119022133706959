import PropTypes from 'prop-types';
const Title = (props) => {
  const {
    text
  } = props;
  return (
    <div className="w-full text-3xl font-bold">
      <span>{text}</span>
    </div>
  )
};

Title.propTypes = {
  text: PropTypes.string,
};

Title.defaultProps = {
  text: ''
};

export default Title;