import PropTypes from 'prop-types';
const Button = (props) => {
  const {classes, text, handler, children, type, disabled} = props;
  
  return (
    <button type={type} className={classes ? `bg-transparent ${classes}`:'flex w-full items-center pr-4 pl-3 py-2 hover:bg-dark hover:text-white hover:border-greenWebsite bg-greenWebsite hover:border-b-2 font-thin'} onClick={()=> handler()} disabled={disabled || false}>
      {children !== null ? (
        children
      ) : (
        text || 'Submit'
      )}
    </button>
  );
}

Button.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string,
  handler: PropTypes.func,
  children: PropTypes.elementType,
  type: PropTypes.string,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  classes: "",
  text: "",
  handler: ()=>{},
  children: null,
  type: "button",
}

export default Button