import Content from "./content";
import SideBar from "./sidebar";
import PropTypes from "prop-types";
const PrivateTemplate = ( props ) => {
  const {children} = props;
  return (
    <div className="h-screen w-full flex overflow-hidden">
      <SideBar/>
      <main className="w-[80%] h-full">
        <Content navbarClasses="w-full bg-white shadow-md h-fit py-2 fixed-top">
          {children}
        </Content>
      </main>
    </div>
  )
}

PrivateTemplate.propTypes = {
  children: PropTypes.elementType
}

export default PrivateTemplate;